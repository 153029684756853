import { Grid } from "@mui/material"
import { Edit, EditProps, SimpleForm, TextInput } from "react-admin"
import { useSearchParams } from "react-router-dom"
import { EditToolbar } from "../../components/EditToolbar"

export const TabletEdit = (props: EditProps) => {
  const [searchParams] = useSearchParams()
  const organizationId = searchParams.get("organizationId") || undefined

  return (
    <Edit
      queryOptions={{
        meta: {
          queryName: "getTabletByIdAndOrganizationId",
          extraFields: {
            organizationId
          }
        }
      }}
      mutationOptions={{
        meta: {
          deleteFields: ["lastInterrogationDate", "totalPatients", "totalReadings"]
        }
      }}
      redirect={() => `organization/${organizationId}/show/tablets`}
      mutationMode="pessimistic"
      title="Edit Tablet"
      {...props}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextInput
              sx={{ display: "none" }}
              source="organizationId"
              defaultValue={organizationId}
              required
              disabled
            />
            <TextInput source="serialNumber" label="Tablet Serial Number" fullWidth autoFocus required disabled />
            <TextInput source="nickname" label="Nickname" fullWidth autoFocus />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}
