import { API, GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api"
import { captureException } from "@sentry/react"
import get from "lodash/get"
import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  HttpError,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult
} from "ra-core"
import { RaRecord } from "react-admin"
import { Filter } from "./Filter"
import { Pagination } from "./Pagination"

export interface Operations {
  queries: Record<string, string>
  mutations: Record<string, string>
}

interface IdMap {
  resource: string
  idField: string
}

interface clientsideFilterMap {
  resource: string
  field: string
}

interface queryNameManyMap {
  operation?: Operation
  resource?: string
  target?: string
  query: string
}

export interface DataProviderOptions {
  authMode?: GRAPHQL_AUTH_MODE
  idMaps?: IdMap[]
  clientsideFilterMaps?: clientsideFilterMap[]
  queryNameManyMaps?: queryNameManyMap[]
}

const defaultOptions = {
  authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
}

export type Operation = "list" | "get" | "create" | "update" | "delete"
export class DataProvider {
  queries: Record<string, string>
  mutations: Record<string, string>

  authMode: GRAPHQL_AUTH_MODE
  constructor(operations: Operations, private options?: DataProviderOptions) {
    this.queries = operations.queries
    this.mutations = operations.mutations
    this.authMode = options?.authMode || defaultOptions.authMode
  }

  private sortData = (data: any, meta: any, params: GetListParams) => {
    return data.sort((a: any, b: any) => {
      const field = params.sort.field
      let valA: string = get(a, field) ?? undefined
      let valB: string = get(b, field) ?? undefined

      /**
       * NOTE: Custom `sortSortFields` are a list of fields that should be parsed or
       * formatted prior to being sorted - this is useful for handling displayed fields
       * that are calculated which may span multiple raw data fields. Also where parsed
       * or formatted values displayed will not match the sort results sort order of the
       * underlying dataset.
       */
      if (meta && meta.customSortFields) {
        const customSortField = meta.customSortFields.find(
          (map: { field: string; fn: (record: RaRecord) => string }) => map.field === field
        )
        if (customSortField) {
          valA = customSortField.fn(a)
          valB = customSortField.fn(b)
        }
      }

      if (params.sort.order === "DESC") {
        if (!valA) return 1
        if (!valB) return -1
        return valB.toString().localeCompare(valA.toString(), undefined, { numeric: true })
      }

      if (!valB) return 1
      if (!valA) return -1
      return valA.toString().localeCompare(valB.toString(), undefined, { numeric: true })
    })
  }

  getList = async (resource: string, params: GetListParams): Promise<GetListResult> => {
    const { filter, meta } = params
    let queryName = Filter.getQueryName(this.queries, filter)
    let queryVariables = Filter.getQueryVariables(filter)

    if (!queryName || !queryVariables) {
      /**
       * If the `queryName` or `queryVariables` are not defined
       * we need to build it in the format: `listResource`
       */
      queryName = this.getQueryName("list", resource)
    }

    const query = this.getQuery(queryName)

    if (!queryVariables) {
      queryVariables = {}
    }

    const { page, perPage } = params.pagination

    const querySignature = JSON.stringify({
      queryName,
      queryVariables,
      perPage
    })

    const nextToken = Pagination.getNextToken(querySignature, page)

    /**
     * If the `nextToken` is `undefined`, it means that the page
     * requested is out of range.
     */
    if (typeof nextToken === "undefined") {
      return {
        data: [],
        total: 0
      }
    }

    if (params.sort.field === queryName) {
      queryVariables["sortDirection"] = params.sort.order
    }

    const queryData = (
      await this.graphql(query, {
        ...queryVariables,
        limit: perPage,
        nextToken
      })
    )[queryName]
    Pagination.saveNextToken(queryData.nextToken, querySignature, page)

    let data = queryData.items.map((item: any) => {
      return this.sanitizeData(resource, item)
    })

    const clientsideFilter = !!this.options?.clientsideFilterMaps?.find(
      (clientsideFilterQueries) =>
        clientsideFilterQueries.resource === resource && filter[clientsideFilterQueries.field]
    )

    if (clientsideFilter) {
      data = data.filter((item: { [key: string]: string | string[] }) => {
        return Object.entries<string | object>(filter)
          .map(([key, filterValue]) => {
            const dataValue = item[key]

            if (dataValue instanceof Array) {
              if (Array.isArray(filterValue)) return filterValue.every((value) => dataValue.includes(value))
              if (typeof filterValue === "string") return dataValue.includes(filterValue)
            }

            if (typeof dataValue == "string") {
              if (Array.isArray(filterValue)) return filterValue.some((value) => dataValue.includes(value))
              if (typeof filterValue === "string") return dataValue.toLowerCase().includes(filterValue.toLowerCase())
            }

            return false
          })
          .every((value) => value === true)
      })
    }

    if (params.sort) {
      try {
        data = this.sortData(data, meta, params)
      } catch (error) {
        captureException(error)
      }
    }
    return {
      data,
      total: data ? data.length : 0
    }
  }

  getOne = async (resource: string, params: GetOneParams): Promise<GetOneResult> => {
    const queryName = params.meta?.queryName ?? this.getQueryName("get", resource)
    const query = this.getQuery(queryName)

    const extraFields = params.meta?.extraFields ?? {}
    const queryData = (await this.graphql(query, this.sanitizeInput(resource, { id: params.id, ...extraFields })))[
      queryName
    ]

    if (!queryData) {
      const error = new HttpError("Not found", 404)
      captureException(error)
      throw error
    }

    return {
      data: this.sanitizeData(resource, queryData)
    }
  }

  getMany = async (resource: string, params: GetManyParams): Promise<GetManyResult> => {
    const queryName = this.getQueryName("get", resource)
    const query = this.getQuery(queryName)
    const queriesData = []
    for (const id of params.ids) {
      const queryData = (await this.graphql(query, this.sanitizeInput(resource, { id })))[queryName]
      if (queryData) {
        queriesData.push(this.sanitizeData(resource, queryData))
      }
    }
    return {
      data: queriesData
    }
  }

  getManyReference = async (resource: string, params: GetManyReferenceParams): Promise<GetManyReferenceResult> => {
    /**
     * // TODO: Check if this fix is solved completely as its potentially a partial fix.
     *
     * This is a workaround for a bug which causes the `getManyReference` to persists the `filter`
     * elements from previous requests and returning an incorrect value from `Filter.getQueryName`.
     *
     * This fix aims to ignore the `filter` element from the `params` and instead build the filter
     * from scratch on each call.
     *
     * This may affect advanced filtering operations or pagination and therefore should be monitored and
     * fixed if required else this TODO and the respective ticket removed.
     *
     * @see {@link https://linear.app/epsy/issue/FE-327/possible-issue-with-getmanyreference}
     */
    const filter: any = {}
    const { id, pagination, sort, target } = params
    const splitTarget = target.split(".")
    /**
     * If the target is a nested field, we need to build the filter
     * in the format `queryName.resourceId`.
     */
    if (splitTarget.length === 2) {
      if (!filter[splitTarget[0]]) {
        filter[splitTarget[0]] = {}
      }
      filter[splitTarget[0]][splitTarget[1]] = id
    } else {
      const queryName = this.getQueryNameMany("list", resource, target)
      if (!filter[queryName]) {
        filter[queryName] = {}
      }
      filter[queryName][target] = id
    }
    return this.getList(resource, { pagination, sort, filter })
  }

  create = async (resource: string, params: CreateParams): Promise<CreateResult> => {
    const queryName = this.getQueryName("create", resource)
    const query = this.getQuery(queryName)
    const queryData = (
      await this.graphql(query, {
        input: this.sanitizeInput(resource, params.data)
      })
    )[queryName]
    return {
      data: this.sanitizeData(resource, queryData)
    }
  }

  update = async (resource: string, params: UpdateParams): Promise<UpdateResult> => {
    const queryName = this.getQueryName("update", resource)
    const query = this.getQuery(queryName)

    /**
     * Removing fields that are not part of the input
     */
    const { data } = params
    delete data._deleted
    delete data._lastChangedAt
    delete data.createdAt
    delete data.updatedAt

    if (params.meta && params.meta.deleteFields) {
      params.meta.deleteFields.forEach((field: string) => delete data[field])
    }

    const queryData = (
      await this.graphql(query, {
        input: this.sanitizeInput(resource, data)
      })
    )[queryName]

    return {
      data: this.sanitizeData(resource, queryData)
    }
  }

  updateMany = async (resource: string, params: UpdateManyParams): Promise<UpdateManyResult> => {
    const queryName = this.getQueryName("update", resource)
    const query = this.getQuery(queryName)
    const { data } = params

    /**
     * Removing fields that are not part of the input
     */
    delete data._deleted
    delete data._lastChangedAt
    delete data.createdAt
    delete data.updatedAt

    const ids = []

    for (const id of params.ids) {
      try {
        const input = this.sanitizeInput(resource, { ...data, id })
        await this.graphql(query, {
          input
        })
        ids.push(id)
      } catch (e) {
        captureException(e)
      }
    }

    return {
      data: ids
    }
  }

  delete = async (resource: string, params: DeleteParams): Promise<DeleteResult> => {
    const queryName = this.getQueryName("delete", resource)
    const query = this.getQuery(queryName)

    const { id, previousData } = params
    let input = { id } as Record<string, unknown>

    if (previousData?._version) {
      input._version = previousData._version
    }

    const extraFields = params.meta?.extraFields
    if (extraFields) {
      input = { ...input, ...extraFields }
    }

    await this.graphql(query, this.sanitizeInput(resource, input))

    return {
      data: { id }
    }
  }

  deleteMany = async (resource: string, params: DeleteManyParams): Promise<DeleteManyResult> => {
    const queryName = this.getQueryName("delete", resource)
    const query = this.getQuery(queryName)
    const ids = []
    for (const id of params.ids) {
      try {
        await this.graphql(query, {
          input: this.sanitizeInput(resource, { id })
        })
        ids.push(id)
      } catch (e) {
        captureException(e)
      }
    }
    return {
      data: ids
    }
  }

  getQuery(queryName: string): string {
    if (this.queries[queryName]) {
      return this.queries[queryName]
    }
    if (this.mutations[queryName]) {
      return this.mutations[queryName]
    }
    const error = new Error(`Data provider error: query/mutation "${queryName}" not found`)
    captureException(error)
    throw error
  }

  getQueryName(operation: Operation, resource: string): string {
    return `${operation}${resource.charAt(0).toUpperCase() + resource.slice(1)}`
  }

  getQueryNameMany(operation: Operation, resource: string, target: string): string {
    const queryName = this.getQueryName(operation, resource)
    const mappedResult = this.options?.queryNameManyMaps?.find((queryNameManyMap) => {
      return (
        (queryNameManyMap.resource ?? resource) === resource &&
        (queryNameManyMap.target ?? target) === target &&
        (queryNameManyMap.operation ?? operation) === operation
      )
    })
    return mappedResult?.query || `${queryName}By${target.charAt(0).toUpperCase() + target.slice(1)}`
  }

  sanitizeData = (resource: string, data: any) => {
    const map = this.getIdMap(resource)
    return map ? this.referenceIdToId(data, map) : data
  }

  sanitizeInput = (resource: string, data: any) => {
    const map = this.getIdMap(resource)
    return map ? this.idToReferenceId(data, map) : data
  }

  getIdMap = (resource: string) => {
    return this.options?.idMaps?.find((map) => map.resource === resource)
  }

  referenceIdToId = (data: Record<string, any>, map: IdMap) => {
    const { [map.idField]: id, ...rest } = data
    return { id, ...rest }
  }

  idToReferenceId = (data: Record<string, any>, map: IdMap) => {
    const { id, ...rest } = data
    return { [map.idField]: id, ...rest }
  }

  async graphql(query: string, variables: Record<string, unknown>): Promise<any> {
    const queryResult = (await API.graphql({
      query,
      variables,
      authMode: this.authMode
    })) as GraphQLResult
    if (queryResult.errors) {
      const error = new Error(`Data provider error: ${JSON.stringify(queryResult.errors)}.`)
      captureException(error)
      throw error
    }
    if (!queryResult.data) {
      const error = new Error(`Data provider error: no data returned.`)
      captureException(error)
      throw error
    }
    return queryResult.data
  }
}
