/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation($input: InvitationInput!) {
    createInvitation(input: $input) {
      conversions
      createdAt
      expiresAt
      invitationId
      organizationId
      recipientEmail
      recipientName
      recipientPhoneCountryCode
      recipientPhoneNumber
      redemptionLimit
      role
      senderUserId
      sentAt
      token
      type
      updatedAt
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      addressLine1
      addressLine2
      city
      country
      department
      email
      logo
      name
      organizationId
      organizationTypes
      phoneNumbers
      state
      territory
      zipCode
    }
  }
`;
export const createTablet = /* GraphQL */ `
  mutation CreateTablet($input: CreateTabletInput!) {
    createTablet(input: $input) {
      createdAt
      id
      lastInterrogationDate
      nickname
      organizationId
      serialNumber
      totalPatients
      totalReadings
      updatedAt
    }
  }
`;
export const deleteHcp = /* GraphQL */ `
  mutation DeleteHcp($memberId: String!) {
    deleteHcp(memberId: $memberId) {
      invitation {
        conversions
        createdAt
        expiresAt
        invitationId
        organizationId
        recipientEmail
        recipientName
        recipientPhoneCountryCode
        recipientPhoneNumber
        redemptionLimit
        role
        senderUserId
        sentAt
        token
        type
        updatedAt
      }
      invitationId
      memberId
      organizationId
      user {
        email
        id
        invitationStatus
        name
        npiNumber
        phoneNumber
        role
      }
      userId
    }
  }
`;
export const deleteInvitation = /* GraphQL */ `
  mutation DeleteInvitation($invitationId: String!) {
    deleteInvitation(invitationId: $invitationId) {
      conversions
      createdAt
      expiresAt
      invitationId
      organizationId
      recipientEmail
      recipientName
      recipientPhoneCountryCode
      recipientPhoneNumber
      redemptionLimit
      role
      senderUserId
      sentAt
      token
      type
      updatedAt
    }
  }
`;
export const deleteTablet = /* GraphQL */ `
  mutation DeleteTablet($id: String!, $organizationId: String!) {
    deleteTablet(id: $id, organizationId: $organizationId)
  }
`;
export const resendInvitation = /* GraphQL */ `
  mutation ResendInvitation($invitationId: String!) {
    resendInvitation(invitationId: $invitationId) {
      conversions
      createdAt
      expiresAt
      invitationId
      organizationId
      recipientEmail
      recipientName
      recipientPhoneCountryCode
      recipientPhoneNumber
      redemptionLimit
      role
      senderUserId
      sentAt
      token
      type
      updatedAt
    }
  }
`;
export const updateInvitation = /* GraphQL */ `
  mutation UpdateInvitation($input: InvitationInput!) {
    updateInvitation(input: $input) {
      conversions
      createdAt
      expiresAt
      invitationId
      organizationId
      recipientEmail
      recipientName
      recipientPhoneCountryCode
      recipientPhoneNumber
      redemptionLimit
      role
      senderUserId
      sentAt
      token
      type
      updatedAt
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      addressLine1
      addressLine2
      city
      country
      department
      email
      logo
      name
      organizationId
      organizationTypes
      phoneNumbers
      state
      territory
      zipCode
    }
  }
`;
export const updateTablet = /* GraphQL */ `
  mutation UpdateTablet($input: UpdateTabletInput!) {
    updateTablet(input: $input) {
      createdAt
      id
      lastInterrogationDate
      nickname
      organizationId
      serialNumber
      totalPatients
      totalReadings
      updatedAt
    }
  }
`;
