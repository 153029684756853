import { Datagrid, ReferenceManyField, TextField } from "react-admin"
import { Total } from "../../../components/Total"
import { ALL_RECORDS_LIMIT } from "../../../constants/pagination"

export const OrganizationShowVnsDevices = () => (
  <ReferenceManyField reference="vnsDevice" target="organizationId" label={false} perPage={ALL_RECORDS_LIMIT}>
    <Total />
    <Datagrid bulkActionButtons={false}>
      <TextField source="generatorSerialNumber" label="VNS Device ID" />
      <TextField source="generatorModel" label="Model" />
    </Datagrid>
    <Total />
  </ReferenceManyField>
)
