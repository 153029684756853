import { Grid } from "@mui/material"
import { Edit, EditProps, SelectArrayInput, SimpleForm, TextInput } from "react-admin"
import { EditToolbar } from "../../components/EditToolbar"
import { organizations } from "./helpers"
import { TerritoryInput } from "./TerritoryInput"
export const OrganizationEdit = (props: EditProps) => {
  return (
    <Edit title="Edit an Organization" {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextInput source="name" fullWidth autoFocus required />
            <TerritoryInput />
            <SelectArrayInput source="organizationTypes" choices={organizations} fullWidth required />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}
