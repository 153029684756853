import { Grid } from "@mui/material"
import {
  Create,
  CreateProps,
  email,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useRedirect
} from "react-admin"
import { useSearchParams } from "react-router-dom"
import { HcpRole, Theme } from "../../API"
import { useFlags } from "../../hooks/useFlags"
import { getChoicesFromEnum } from "../../utils/get-choices-from-enum"

export const HcpCreate = (props: CreateProps) => {
  const { hcpInvitationRole } = useFlags()
  const [searchParams] = useSearchParams()
  const organizationId = searchParams.get("organizationId") || undefined
  const rediect = useRedirect()
  if (!organizationId) {
    rediect("/organization")
    return null
  }
  return (
    <Create title="Create HCP" {...props} redirect={() => `organization/${organizationId}/show`}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextInput
              sx={{ display: "none" }}
              source="organizationId"
              defaultValue={organizationId}
              required
              disabled
            />
            <TextInput
              sx={{ display: "none" }}
              source="type"
              defaultValue="hcp_inviting_specific_staff_to_org_via_email"
              required
              disabled
            />
            <TextInput source="recipientName" label="Full name" fullWidth autoFocus required />
            <TextInput
              source="recipientEmail"
              label="Email address"
              fullWidth
              required
              validate={email("Please enter a valid email")}
            />
            <SelectInput
              sx={hcpInvitationRole ? { display: "none" } : undefined}
              source="role"
              label="Role"
              choices={getChoicesFromEnum(HcpRole)}
              defaultValue={hcpInvitationRole || HcpRole.Manager}
              disabled={!!hcpInvitationRole}
              required
            />
            <SelectInput source="theme" label="Platform" choices={getChoicesFromEnum(Theme)} required />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  )
}
