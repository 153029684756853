import { captureEvent } from "@sentry/react"

export function craEnv(suffix: EnvSuffix): string {
  return process.env[`REACT_APP_${suffix}`] || ""
}

const envSuffix = [
  "ENV",
  "VERSION",
  "LAUNCH_DARKLY_CLIENT_ID",
  "SENTRY_DSN",
  "SENTRY_TRACES_SAMPLE_RATE",
  "SENTRY_REPLAYS_SESSION_SAMPLE_RATE",
  "SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE"
] as const

type EnvSuffix = (typeof envSuffix)[number]

const envs = ["play", "dev", "staging", "prod"] as const
type Env = (typeof envs)[number]

interface Environment {
  env: Env
  version: string
  launchDarklyId: string
  sentryDsn?: string
  sentryTracesSampleRate: number
  sentryReplaysSessionSampleRate: number
  sentryReplaysOnErrorSampleRate: number
  isLocalEnv: boolean
}

function guardEnv(env: string): Env {
  const throwError = () => {
    const error = new Error(
      `As invlaid value '${env}' has been set for environment variable 'REACT_APP_ENV' (valid values are: ${envs.join(
        " |"
      )})`
    )
    captureEvent(error)
    throw error
  }
  return envs.includes(env as Env) ? (env as Env) : throwError()
}

const environment: Environment = {
  env: guardEnv(craEnv("ENV")),
  isLocalEnv: process.env.NODE_ENV === "development",
  version: craEnv("VERSION"),
  launchDarklyId: craEnv("LAUNCH_DARKLY_CLIENT_ID"),
  sentryDsn: craEnv("SENTRY_DSN"),
  sentryTracesSampleRate: Number(craEnv("SENTRY_TRACES_SAMPLE_RATE") ?? 0),
  sentryReplaysSessionSampleRate: Number(craEnv("SENTRY_REPLAYS_SESSION_SAMPLE_RATE") ?? 0),
  sentryReplaysOnErrorSampleRate: Number(craEnv("SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE") ?? 0)
}

export { environment }
