import { Auth } from "aws-amplify"
import { buildAuthProvider as raBuildAuthProvider } from "react-admin-amplify"
import { AuthProviderOptions } from "react-admin-amplify/build/providers/AuthProvider"

export const buildAuthProvider = (options?: AuthProviderOptions) => {
  return {
    ...raBuildAuthProvider(options),
    getIdentity: async () => {
      const {
        attributes: { email, email_verified: emailVerified },
        username: id
      } = await Auth.currentAuthenticatedUser()
      return { id, email, emailVerified }
    }
  }
}
