/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type InvitationInput = {
  invitationId?: string | null,
  organizationId: string,
  patientPublicId?: string | null,
  recipientEmail?: string | null,
  recipientName?: string | null,
  recipientPhoneCountryCode?: string | null,
  recipientPhoneNumber?: string | null,
  redemptionLimit?: number | null,
  role?: HcpRole | null,
  senderUserId?: string | null,
  sentAt?: Array< string | null > | null,
  theme: Theme,
  token?: string | null,
  type: InvitationType,
};

export enum HcpRole {
  Admin = "Admin",
  Manager = "Manager",
  Owner = "Owner",
}


export enum Theme {
  Epsy = "Epsy",
  LivaNova = "LivaNova",
}


export enum InvitationType {
  hcp_inviting_any_patient_to_org_via_emr = "hcp_inviting_any_patient_to_org_via_emr",
  hcp_inviting_specific_patient_to_org_via_email = "hcp_inviting_specific_patient_to_org_via_email",
  hcp_inviting_specific_patient_to_org_via_sms = "hcp_inviting_specific_patient_to_org_via_sms",
  hcp_inviting_specific_staff_to_org_via_email = "hcp_inviting_specific_staff_to_org_via_email",
  org_inviting_any_patient_to_org_via_digital_media_qr = "org_inviting_any_patient_to_org_via_digital_media_qr",
  org_inviting_any_patient_to_org_via_print_media_qr = "org_inviting_any_patient_to_org_via_print_media_qr",
}


export type Invitation = {
  __typename: "Invitation",
  conversions?: Array< string | null > | null,
  createdAt?: string | null,
  expiresAt?: string | null,
  invitationId: string,
  organizationId: string,
  recipientEmail?: string | null,
  recipientName?: string | null,
  recipientPhoneCountryCode?: string | null,
  recipientPhoneNumber?: string | null,
  redemptionLimit?: number | null,
  role?: HcpRole | null,
  senderUserId?: string | null,
  sentAt?: Array< string | null > | null,
  token?: string | null,
  type?: InvitationType | null,
  updatedAt?: string | null,
};

export type CreateOrganizationInput = {
  addressLine1?: string | null,
  addressLine2?: string | null,
  city?: string | null,
  country?: string | null,
  department?: string | null,
  email?: string | null,
  logo?: string | null,
  name: string,
  organizationTypes: Array< OrganizationType >,
  phoneNumbers?: Array< string | null > | null,
  state?: string | null,
  territory?: OrganizationTerritory | null,
  zipCode?: string | null,
};

export enum OrganizationType {
  EPSY_HUB = "EPSY_HUB",
  VNS_PORTAL = "VNS_PORTAL",
}


export enum OrganizationTerritory {
  ALABAMA_AL_6245 = "ALABAMA_AL_6245",
  ALBUQUERQUE_NM_6363 = "ALBUQUERQUE_NM_6363",
  ALLENTOWN_PA_6222 = "ALLENTOWN_PA_6222",
  ARKANSAS_6271 = "ARKANSAS_6271",
  ATLANTA_GA_6251 = "ATLANTA_GA_6251",
  ATLANTA_GA_KEYACCTS_6851 = "ATLANTA_GA_KEYACCTS_6851",
  AUSTIN_TX_6323 = "AUSTIN_TX_6323",
  BOISE_ID_6341 = "BOISE_ID_6341",
  BOSTON_MA_6235 = "BOSTON_MA_6235",
  BOSTON_MA_KEYACCTS_6835 = "BOSTON_MA_KEYACCTS_6835",
  BUFFALO_NY_6226 = "BUFFALO_NY_6226",
  CENTRAL_ILLINOIS_IL_6284 = "CENTRAL_ILLINOIS_IL_6284",
  CHARLOTTE_NC_6255 = "CHARLOTTE_NC_6255",
  CHICAGO_CENTRAL_IL_6282 = "CHICAGO_CENTRAL_IL_6282",
  CHICAGO_CENTRAL_IL_KEYACCTS_6882 = "CHICAGO_CENTRAL_IL_KEYACCTS_6882",
  CHICAGO_N_IL_6281 = "CHICAGO_N_IL_6281",
  CHICAGO_N_IL_KEYACCTS_6881 = "CHICAGO_N_IL_KEYACCTS_6881",
  CHICAGO_S_IL_6283 = "CHICAGO_S_IL_6283",
  CINCINNATI_OH_6264 = "CINCINNATI_OH_6264",
  CLEVELAND_OH_6265 = "CLEVELAND_OH_6265",
  CLEVELAND_OH_KEYACCTS_6865 = "CLEVELAND_OH_KEYACCTS_6865",
  COLUMBIA_SC_6256 = "COLUMBIA_SC_6256",
  COLUMBIA_SC_KEYACCTS_6856 = "COLUMBIA_SC_KEYACCTS_6856",
  COLUMBUS_OH_6266 = "COLUMBUS_OH_6266",
  COLUMBUS_OH_KEYACCTS_6866 = "COLUMBUS_OH_KEYACCTS_6866",
  DAKOTAS_6332 = "DAKOTAS_6332",
  DALLAS_TX_6313 = "DALLAS_TX_6313",
  DALLAS_TX_KEYACCTS_6913 = "DALLAS_TX_KEYACCTS_6913",
  DENVER_CO_6354 = "DENVER_CO_6354",
  DENVER_CO_KEYACCTS_6954 = "DENVER_CO_KEYACCTS_6954",
  DUMMY_US_0000 = "DUMMY_US_0000",
  FLORIDA_EAST_FL_6244 = "FLORIDA_EAST_FL_6244",
  FORT_WORTH_TX_6312 = "FORT_WORTH_TX_6312",
  FORT_WORTH_TX_KEYACCTS_6912 = "FORT_WORTH_TX_KEYACCTS_6912",
  FRESNO_6373 = "FRESNO_6373",
  HARRISBURG_PA_6224 = "HARRISBURG_PA_6224",
  HAWAII_6376 = "HAWAII_6376",
  HOUSTON_NORTH_TX_6324 = "HOUSTON_NORTH_TX_6324",
  HOUSTON_NORTH_TX_KEYACCTS_6924 = "HOUSTON_NORTH_TX_KEYACCTS_6924",
  HOUSTON_SOUTH_TX_6325 = "HOUSTON_SOUTH_TX_6325",
  HOUSTON_SOUTH_TX_KEYACCTS_6925 = "HOUSTON_SOUTH_TX_KEYACCTS_6925",
  INDIANA_CENTRAL_IN_6276 = "INDIANA_CENTRAL_IN_6276",
  INDIANA_SOUTH_IN_6277 = "INDIANA_SOUTH_IN_6277",
  INLAND_EMPIRE_CA_6365 = "INLAND_EMPIRE_CA_6365",
  IOWA_IA_6336 = "IOWA_IA_6336",
  KANSAS_CITY_MO_6335 = "KANSAS_CITY_MO_6335",
  KANSAS_CITY_WEST_6334 = "KANSAS_CITY_WEST_6334",
  KENTUCKY_KY_6261 = "KENTUCKY_KY_6261",
  LAS_VEGAS_NV_6352 = "LAS_VEGAS_NV_6352",
  LONG_ISLAND_NY_6233 = "LONG_ISLAND_NY_6233",
  LOS_ANGELES_NORTH_CA_6375 = "LOS_ANGELES_NORTH_CA_6375",
  LOS_ANGELES_NORTH_CA_KEYACCTS_6975 = "LOS_ANGELES_NORTH_CA_KEYACCTS_6975",
  LOS_ANGELES_SOUTH_CA_6374 = "LOS_ANGELES_SOUTH_CA_6374",
  LOS_ANGELES_SOUTH_CA_KEYACCTS_6974 = "LOS_ANGELES_SOUTH_CA_KEYACCTS_6974",
  LOUISIANA_LA_6321 = "LOUISIANA_LA_6321",
  MANHATTAN_NY_6234 = "MANHATTAN_NY_6234",
  MANHATTAN_NY_KEYACCTS_6834 = "MANHATTAN_NY_KEYACCTS_6834",
  MIAMI_FL_6241 = "MIAMI_FL_6241",
  MIAMI_FL_KEYACCTS_6841 = "MIAMI_FL_KEYACCTS_6841",
  MICHIGAN_EAST_MI_6263 = "MICHIGAN_EAST_MI_6263",
  MICHIGAN_EAST_MI_KEYACCTS_6863 = "MICHIGAN_EAST_MI_KEYACCTS_6863",
  MICHIGAN_WEST_MI_6262 = "MICHIGAN_WEST_MI_6262",
  MILWAUKEE_WI_6285 = "MILWAUKEE_WI_6285",
  MINNEAPOLIS_MN_6331 = "MINNEAPOLIS_MN_6331",
  MINNEAPOLIS_MN_KEYACCTS_6931 = "MINNEAPOLIS_MN_KEYACCTS_6931",
  MONTANA_6345 = "MONTANA_6345",
  MONUMENT_CO_6353 = "MONUMENT_CO_6353",
  MONUMENT_CO_KEYACCTS_6953 = "MONUMENT_CO_KEYACCTS_6953",
  NEW_ENGLAND_6236 = "NEW_ENGLAND_6236",
  NEW_JERSEY_6231 = "NEW_JERSEY_6231",
  NORTH_LOUISIANA_LA_6311 = "NORTH_LOUISIANA_LA_6311",
  OKLAHOMA_OK_6314 = "OKLAHOMA_OK_6314",
  OKLAHOMA_OK_KEYACCTS_6914 = "OKLAHOMA_OK_KEYACCTS_6914",
  OMAHA_NE_6333 = "OMAHA_NE_6333",
  ORLANDO_FL_6242 = "ORLANDO_FL_6242",
  PHILADELPHIA_PA_6232 = "PHILADELPHIA_PA_6232",
  PHILADELPHIA_PA_KEYACCTS_6832 = "PHILADELPHIA_PA_KEYACCTS_6832",
  PHOENIX_AZ_6361 = "PHOENIX_AZ_6361",
  PHOENIX_AZ_KEYACCTS_6961 = "PHOENIX_AZ_KEYACCTS_6961",
  PITTSBURGH_NORTH_PA_6221 = "PITTSBURGH_NORTH_PA_6221",
  PITTSBURGH_NORTH_PA_KEYACCTS_6821 = "PITTSBURGH_NORTH_PA_KEYACCTS_6821",
  PORTLAND_OR_6344 = "PORTLAND_OR_6344",
  PORTLAND_OR_KEYACCTS_6944 = "PORTLAND_OR_KEYACCTS_6944",
  RALEIGH_NC_6253 = "RALEIGH_NC_6253",
  RALEIGH_NC_KEYACCTS_6853 = "RALEIGH_NC_KEYACCTS_6853",
  SACRAMENTO_CA_6371 = "SACRAMENTO_CA_6371",
  SALT_LAKE_CITY_UT_6351 = "SALT_LAKE_CITY_UT_6351",
  SAN_ANTONIO_TX_6322 = "SAN_ANTONIO_TX_6322",
  SAN_DIEGO_CA_6364 = "SAN_DIEGO_CA_6364",
  SAN_FRANCISCO_BAY_CA_6372 = "SAN_FRANCISCO_BAY_CA_6372",
  SAN_FRANCISCO_BAY_CA_KEYACCTS_6972 = "SAN_FRANCISCO_BAY_CA_KEYACCTS_6972",
  SEATTLE_WA_6342 = "SEATTLE_WA_6342",
  SEATTLE_WA_KEYACCTS_6942 = "SEATTLE_WA_KEYACCTS_6942",
  ST_LOUIS_MO_6272 = "ST_LOUIS_MO_6272",
  SYRACUSE_NY_6225 = "SYRACUSE_NY_6225",
  TACOMA_6343 = "TACOMA_6343",
  TAMPA_FL_6243 = "TAMPA_FL_6243",
  TAMPA_FL_KEYACCTS_6843 = "TAMPA_FL_KEYACCTS_6843",
  TENNESSEE_CENTRAL_TN_6275 = "TENNESSEE_CENTRAL_TN_6275",
  TENNESSEE_EAST_TN_6274 = "TENNESSEE_EAST_TN_6274",
  TENNESSEE_WEST_TN_6273 = "TENNESSEE_WEST_TN_6273",
  TUCSON_AZ_6362 = "TUCSON_AZ_6362",
  VIRGINIA_VA_6252 = "VIRGINIA_VA_6252",
  VIRGINIA_VA_KEYACCTS_6852 = "VIRGINIA_VA_KEYACCTS_6852",
  WASHINGTON_DC_6227 = "WASHINGTON_DC_6227",
  WASHINGTON_DC_KEYACCTS_6827 = "WASHINGTON_DC_KEYACCTS_6827",
  WEST_VIRGINIA_6223 = "WEST_VIRGINIA_6223",
  WEST_VIRGINIA_KEYACCTS_6823 = "WEST_VIRGINIA_KEYACCTS_6823",
  WILMINGTON_NC_6254 = "WILMINGTON_NC_6254",
}


export type Organization = {
  __typename: "Organization",
  addressLine1?: string | null,
  addressLine2?: string | null,
  city?: string | null,
  country?: string | null,
  department?: string | null,
  email?: string | null,
  logo?: string | null,
  name?: string | null,
  organizationId: string,
  organizationTypes: Array< OrganizationType >,
  phoneNumbers?: Array< string | null > | null,
  state?: string | null,
  territory?: OrganizationTerritory | null,
  zipCode?: string | null,
};

export type CreateTabletInput = {
  nickname?: string | null,
  organizationId: string,
  serialNumber: string,
};

export type Tablet = {
  __typename: "Tablet",
  createdAt?: string | null,
  id: string,
  lastInterrogationDate?: string | null,
  nickname?: string | null,
  organizationId: string,
  serialNumber: string,
  totalPatients?: number | null,
  totalReadings?: number | null,
  updatedAt?: string | null,
};

export type Hcp = {
  __typename: "Hcp",
  invitation?: Invitation | null,
  invitationId?: string | null,
  memberId: string,
  organizationId: string,
  user?: HcpUser | null,
  userId?: string | null,
};

export type HcpUser = {
  __typename: "HcpUser",
  email: string,
  id: string,
  invitationStatus?: InvitationStatus | null,
  name?: string | null,
  npiNumber?: string | null,
  phoneNumber?: string | null,
  role?: HcpRole | null,
};

export enum InvitationStatus {
  ACCEPTED = "ACCEPTED",
  EXPIRED = "EXPIRED",
  PENDING = "PENDING",
}


export type UpdateOrganizationInput = {
  addressLine1?: string | null,
  addressLine2?: string | null,
  city?: string | null,
  country?: string | null,
  department?: string | null,
  email?: string | null,
  logo?: string | null,
  name?: string | null,
  organizationId: string,
  organizationTypes: Array< OrganizationType >,
  phoneNumbers?: Array< string | null > | null,
  state?: string | null,
  territory?: OrganizationTerritory | null,
  zipCode?: string | null,
};

export type UpdateTabletInput = {
  id?: string | null,
  nickname?: string | null,
  organizationId: string,
  serialNumber: string,
};

export type HcpList = {
  __typename: "HcpList",
  items?:  Array<Hcp | null > | null,
  nextToken?: string | null,
};

export type InvitationList = {
  __typename: "InvitationList",
  items?:  Array<Invitation | null > | null,
  nextToken?: string | null,
};

export type OrganizationList = {
  __typename: "OrganizationList",
  items?:  Array<Organization | null > | null,
  nextToken?: string | null,
};

export type PatientList = {
  __typename: "PatientList",
  items:  Array<Patient >,
  nextToken?: string | null,
};

export type Patient = {
  __typename: "Patient",
  invitation?: Invitation | null,
  invitationId?: string | null,
  memberId: string,
  user?: User | null,
  userId?: string | null,
};

export type User = {
  __typename: "User",
  acceptedAt?: string | null,
  analyticsId?: string | null,
  appsflyerId?: string | null,
  birthdate?: string | null,
  createdAt?: string | null,
  dob?: string | null,
  email: string,
  emailVerified?: boolean | null,
  gender?: string | null,
  id: string,
  includeInAnalytics?: boolean | null,
  isMinor?: boolean | null,
  isOnboard?: boolean | null,
  lastSentAt?: string | null,
  legacyId?: number | null,
  locale?: string | null,
  marketingOptIn?: boolean | null,
  name?: string | null,
  notificationsSound?: boolean | null,
  overlayIsFirstEventOverlayViewed?: boolean | null,
  overlayIsFirstMedOverlayViewed?: boolean | null,
  pastMedicationsCount?: string | null,
  phoneNumber?: string | null,
  platform?: string | null,
  profileCompleteDismissed?: boolean | null,
  profileShareCodeRequested?: boolean | null,
  roles?: Array< string > | null,
  seizureAware?: number | null,
  seizureConvulsive?: number | null,
  seizureFrequency?: string | null,
  synchronizedAt?: string | null,
  tasksAddMeds?: string | null,
  tasksContactCaseManager?: string | null,
  tasksHealthData?: string | null,
  tasksLogFirstEvent?: string | null,
  tasksLogFirstMed?: string | null,
  tasksMedsTried?: string | null,
  tasksReadDreArticle?: string | null,
  tasksVisitVnsWebsite?: string | null,
  updatedAt?: string | null,
  watchRecordingEnabled?: boolean | null,
  yearsSinceDiagnosis?: string | null,
  zoneinfo?: string | null,
};

export type PatientProfileList = {
  __typename: "PatientProfileList",
  items?:  Array<PatientProfile | null > | null,
  nextToken?: string | null,
};

export type PatientProfile = {
  __typename: "PatientProfile",
  IPGs?:  Array<IPG | null > | null,
  assignedHcps:  Array<Hcp >,
  birthDate?: string | null,
  currentIPG?: IPG | null,
  deathDate?: string | null,
  disDate?: string | null,
  eosCalculation?: EOSCalculation | null,
  expDate?: string | null,
  gender?: string | null,
  id: string,
  indication?: string | null,
  lastReading?: VNSReading | null,
  managingPhysician?: Physician | null,
  msid?: string | null,
  name?: string | null,
  part?: string | null,
  patientId?: string | null,
  referringPhysician?: Physician | null,
  surgeon?: Physician | null,
};

export type IPG = {
  __typename: "IPG",
  implantingDate?: string | null,
  implantingHospitalSortName?: string | null,
  model?: string | null,
  replacementReason?: string | null,
  returnDate?: string | null,
  serial?: string | null,
  softwareVersion?: string | null,
};

export type EOSCalculation = {
  __typename: "EOSCalculation",
  calculationDate?: string | null,
  expectedLifeRemaining?: number | null,
  maximumLifeRemaining?: number | null,
  minimalLifeRemaining?: number | null,
};

export type VNSReading = {
  __typename: "VNSReading",
  autoStimulationBlackoutTime?: string | null,
  autoStimulationOn?: boolean | null,
  autoStimulationThreshold?: string | null,
  autoStimulationTimePercent?: string | null,
  beatsPerMin?: string | null,
  chargePerDay?: number | null,
  dutyCycle?: string | null,
  fetDate?: string | null,
  heartbeatDetection?: number | null,
  id: string,
  importDate?: string | null,
  interrogationDate?: string | null,
  ipg?: IPG | null,
  isEOS?: boolean | null,
  isNEOS?: boolean | null,
  leadImpedanceStatus?: string | null,
  leadImpedanceValue?: string | null,
  magnetStimulationTime?: string | null,
  msid?: string | null,
  nightMode?: Flag | null,
  nightSettings?: Settings | null,
  normalSettings?: Settings | null,
  normalStimulationTime?: string | null,
  offTime?: string | null,
  onTime?: string | null,
  organizationId?: string | null,
  programmingMode?: string | null,
  readingMode?: VNSMode | null,
  scheduledPrograming?: Flag | null,
  seizureDetection?: Flag | null,
  sessionId?: number | null,
  tablet?: VNSReadingTablet | null,
  therapyProtocolType?: string | null,
  time?: number | null,
  totalOnTimeHours?: string | null,
  totalOnTimeMinutes?: string | null,
  uniqueOfficeVisit?: string | null,
  wand?: Wand | null,
};

export enum Flag {
  OFF = "OFF",
  ON = "ON",
}


export type Settings = {
  __typename: "Settings",
  autoStimulationOnTime?: string | null,
  autoStimulationOutputCurrent?: string | null,
  autoStimulationPulseWidth?: string | null,
  endTime?: string | null,
  magnetOutputCurrent?: string | null,
  magnetPulseWidth?: string | null,
  magnetSignalOnTime?: string | null,
  normalFrequency?: string | null,
  normalOutputCurrent?: string | null,
  normalPulseWidth?: string | null,
  normalSignalOffTime?: string | null,
  normalSignalOnTime?: string | null,
  startTime?: string | null,
};

export enum VNSMode {
  INTERROGATE = "INTERROGATE",
  PROGRAM = "PROGRAM",
  SCHEDULE = "SCHEDULE",
}


export type VNSReadingTablet = {
  __typename: "VNSReadingTablet",
  serial?: string | null,
  softwareVersion?: string | null,
};

export type Wand = {
  __typename: "Wand",
  serial?: string | null,
  softwareVersion?: string | null,
};

export type Physician = {
  __typename: "Physician",
  NPI?: string | null,
  name?: string | null,
};

export type TabletList = {
  __typename: "TabletList",
  items?:  Array<Tablet | null > | null,
  nextToken?: string | null,
};

export type UserFilterInput = {
  email?: string | null,
};

export type UserList = {
  __typename: "UserList",
  items:  Array<User >,
  nextToken?: string | null,
};

export type VnsDeviceSummaryList = {
  __typename: "VnsDeviceSummaryList",
  items:  Array<VnsDeviceSummary >,
};

export type VnsDeviceSummary = {
  __typename: "VnsDeviceSummary",
  blc?: string | null,
  generatorModel: string,
  generatorSerialNumber: number,
  implantedDate?: string | null,
  lastKnownReading?: string | null,
  normalOutputCurrent?: string | null,
  normalPulseWidth?: number | null,
  patientDob?: string | null,
  patientName?: string | null,
  patientProfileId: string,
};

export type CreateInvitationMutationVariables = {
  input: InvitationInput,
};

export type CreateInvitationMutation = {
  createInvitation?:  {
    __typename: "Invitation",
    conversions?: Array< string | null > | null,
    createdAt?: string | null,
    expiresAt?: string | null,
    invitationId: string,
    organizationId: string,
    recipientEmail?: string | null,
    recipientName?: string | null,
    recipientPhoneCountryCode?: string | null,
    recipientPhoneNumber?: string | null,
    redemptionLimit?: number | null,
    role?: HcpRole | null,
    senderUserId?: string | null,
    sentAt?: Array< string | null > | null,
    token?: string | null,
    type?: InvitationType | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    addressLine1?: string | null,
    addressLine2?: string | null,
    city?: string | null,
    country?: string | null,
    department?: string | null,
    email?: string | null,
    logo?: string | null,
    name?: string | null,
    organizationId: string,
    organizationTypes: Array< OrganizationType >,
    phoneNumbers?: Array< string | null > | null,
    state?: string | null,
    territory?: OrganizationTerritory | null,
    zipCode?: string | null,
  } | null,
};

export type CreateTabletMutationVariables = {
  input: CreateTabletInput,
};

export type CreateTabletMutation = {
  createTablet?:  {
    __typename: "Tablet",
    createdAt?: string | null,
    id: string,
    lastInterrogationDate?: string | null,
    nickname?: string | null,
    organizationId: string,
    serialNumber: string,
    totalPatients?: number | null,
    totalReadings?: number | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteHcpMutationVariables = {
  memberId: string,
};

export type DeleteHcpMutation = {
  deleteHcp?:  {
    __typename: "Hcp",
    invitation?:  {
      __typename: "Invitation",
      conversions?: Array< string | null > | null,
      createdAt?: string | null,
      expiresAt?: string | null,
      invitationId: string,
      organizationId: string,
      recipientEmail?: string | null,
      recipientName?: string | null,
      recipientPhoneCountryCode?: string | null,
      recipientPhoneNumber?: string | null,
      redemptionLimit?: number | null,
      role?: HcpRole | null,
      senderUserId?: string | null,
      sentAt?: Array< string | null > | null,
      token?: string | null,
      type?: InvitationType | null,
      updatedAt?: string | null,
    } | null,
    invitationId?: string | null,
    memberId: string,
    organizationId: string,
    user?:  {
      __typename: "HcpUser",
      email: string,
      id: string,
      invitationStatus?: InvitationStatus | null,
      name?: string | null,
      npiNumber?: string | null,
      phoneNumber?: string | null,
      role?: HcpRole | null,
    } | null,
    userId?: string | null,
  } | null,
};

export type DeleteInvitationMutationVariables = {
  invitationId: string,
};

export type DeleteInvitationMutation = {
  deleteInvitation?:  {
    __typename: "Invitation",
    conversions?: Array< string | null > | null,
    createdAt?: string | null,
    expiresAt?: string | null,
    invitationId: string,
    organizationId: string,
    recipientEmail?: string | null,
    recipientName?: string | null,
    recipientPhoneCountryCode?: string | null,
    recipientPhoneNumber?: string | null,
    redemptionLimit?: number | null,
    role?: HcpRole | null,
    senderUserId?: string | null,
    sentAt?: Array< string | null > | null,
    token?: string | null,
    type?: InvitationType | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteTabletMutationVariables = {
  id: string,
  organizationId: string,
};

export type DeleteTabletMutation = {
  deleteTablet?: boolean | null,
};

export type ResendInvitationMutationVariables = {
  invitationId: string,
};

export type ResendInvitationMutation = {
  resendInvitation?:  {
    __typename: "Invitation",
    conversions?: Array< string | null > | null,
    createdAt?: string | null,
    expiresAt?: string | null,
    invitationId: string,
    organizationId: string,
    recipientEmail?: string | null,
    recipientName?: string | null,
    recipientPhoneCountryCode?: string | null,
    recipientPhoneNumber?: string | null,
    redemptionLimit?: number | null,
    role?: HcpRole | null,
    senderUserId?: string | null,
    sentAt?: Array< string | null > | null,
    token?: string | null,
    type?: InvitationType | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateInvitationMutationVariables = {
  input: InvitationInput,
};

export type UpdateInvitationMutation = {
  updateInvitation?:  {
    __typename: "Invitation",
    conversions?: Array< string | null > | null,
    createdAt?: string | null,
    expiresAt?: string | null,
    invitationId: string,
    organizationId: string,
    recipientEmail?: string | null,
    recipientName?: string | null,
    recipientPhoneCountryCode?: string | null,
    recipientPhoneNumber?: string | null,
    redemptionLimit?: number | null,
    role?: HcpRole | null,
    senderUserId?: string | null,
    sentAt?: Array< string | null > | null,
    token?: string | null,
    type?: InvitationType | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    addressLine1?: string | null,
    addressLine2?: string | null,
    city?: string | null,
    country?: string | null,
    department?: string | null,
    email?: string | null,
    logo?: string | null,
    name?: string | null,
    organizationId: string,
    organizationTypes: Array< OrganizationType >,
    phoneNumbers?: Array< string | null > | null,
    state?: string | null,
    territory?: OrganizationTerritory | null,
    zipCode?: string | null,
  } | null,
};

export type UpdateTabletMutationVariables = {
  input: UpdateTabletInput,
};

export type UpdateTabletMutation = {
  updateTablet?:  {
    __typename: "Tablet",
    createdAt?: string | null,
    id: string,
    lastInterrogationDate?: string | null,
    nickname?: string | null,
    organizationId: string,
    serialNumber: string,
    totalPatients?: number | null,
    totalReadings?: number | null,
    updatedAt?: string | null,
  } | null,
};

export type GetOrganizationQueryVariables = {
  organizationId: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    addressLine1?: string | null,
    addressLine2?: string | null,
    city?: string | null,
    country?: string | null,
    department?: string | null,
    email?: string | null,
    logo?: string | null,
    name?: string | null,
    organizationId: string,
    organizationTypes: Array< OrganizationType >,
    phoneNumbers?: Array< string | null > | null,
    state?: string | null,
    territory?: OrganizationTerritory | null,
    zipCode?: string | null,
  } | null,
};

export type GetTabletByIdAndOrganizationIdQueryVariables = {
  id: string,
  organizationId: string,
};

export type GetTabletByIdAndOrganizationIdQuery = {
  getTabletByIdAndOrganizationId?:  {
    __typename: "Tablet",
    createdAt?: string | null,
    id: string,
    lastInterrogationDate?: string | null,
    nickname?: string | null,
    organizationId: string,
    serialNumber: string,
    totalPatients?: number | null,
    totalReadings?: number | null,
    updatedAt?: string | null,
  } | null,
};

export type ListHcpQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  organizationId: string,
};

export type ListHcpQuery = {
  listHcp?:  {
    __typename: "HcpList",
    items?:  Array< {
      __typename: "Hcp",
      invitation?:  {
        __typename: "Invitation",
        conversions?: Array< string | null > | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        invitationId: string,
        organizationId: string,
        recipientEmail?: string | null,
        recipientName?: string | null,
        recipientPhoneCountryCode?: string | null,
        recipientPhoneNumber?: string | null,
        redemptionLimit?: number | null,
        role?: HcpRole | null,
        senderUserId?: string | null,
        sentAt?: Array< string | null > | null,
        token?: string | null,
        type?: InvitationType | null,
        updatedAt?: string | null,
      } | null,
      invitationId?: string | null,
      memberId: string,
      organizationId: string,
      user?:  {
        __typename: "HcpUser",
        email: string,
        id: string,
        invitationStatus?: InvitationStatus | null,
        name?: string | null,
        npiNumber?: string | null,
        phoneNumber?: string | null,
        role?: HcpRole | null,
      } | null,
      userId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListInvitationByOrganizationIdQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  organizationId: string,
};

export type ListInvitationByOrganizationIdQuery = {
  listInvitationByOrganizationId?:  {
    __typename: "InvitationList",
    items?:  Array< {
      __typename: "Invitation",
      conversions?: Array< string | null > | null,
      createdAt?: string | null,
      expiresAt?: string | null,
      invitationId: string,
      organizationId: string,
      recipientEmail?: string | null,
      recipientName?: string | null,
      recipientPhoneCountryCode?: string | null,
      recipientPhoneNumber?: string | null,
      redemptionLimit?: number | null,
      role?: HcpRole | null,
      senderUserId?: string | null,
      sentAt?: Array< string | null > | null,
      token?: string | null,
      type?: InvitationType | null,
      updatedAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListOrganizationQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationQuery = {
  listOrganization?:  {
    __typename: "OrganizationList",
    items?:  Array< {
      __typename: "Organization",
      addressLine1?: string | null,
      addressLine2?: string | null,
      city?: string | null,
      country?: string | null,
      department?: string | null,
      email?: string | null,
      logo?: string | null,
      name?: string | null,
      organizationId: string,
      organizationTypes: Array< OrganizationType >,
      phoneNumbers?: Array< string | null > | null,
      state?: string | null,
      territory?: OrganizationTerritory | null,
      zipCode?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPatientByOrganizationIdQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  organizationId: string,
};

export type ListPatientByOrganizationIdQuery = {
  listPatientByOrganizationId?:  {
    __typename: "PatientList",
    items:  Array< {
      __typename: "Patient",
      invitation?:  {
        __typename: "Invitation",
        conversions?: Array< string | null > | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        invitationId: string,
        organizationId: string,
        recipientEmail?: string | null,
        recipientName?: string | null,
        recipientPhoneCountryCode?: string | null,
        recipientPhoneNumber?: string | null,
        redemptionLimit?: number | null,
        role?: HcpRole | null,
        senderUserId?: string | null,
        sentAt?: Array< string | null > | null,
        token?: string | null,
        type?: InvitationType | null,
        updatedAt?: string | null,
      } | null,
      invitationId?: string | null,
      memberId: string,
      user?:  {
        __typename: "User",
        acceptedAt?: string | null,
        analyticsId?: string | null,
        appsflyerId?: string | null,
        birthdate?: string | null,
        createdAt?: string | null,
        dob?: string | null,
        email: string,
        emailVerified?: boolean | null,
        gender?: string | null,
        id: string,
        includeInAnalytics?: boolean | null,
        isMinor?: boolean | null,
        isOnboard?: boolean | null,
        lastSentAt?: string | null,
        legacyId?: number | null,
        locale?: string | null,
        marketingOptIn?: boolean | null,
        name?: string | null,
        notificationsSound?: boolean | null,
        overlayIsFirstEventOverlayViewed?: boolean | null,
        overlayIsFirstMedOverlayViewed?: boolean | null,
        pastMedicationsCount?: string | null,
        phoneNumber?: string | null,
        platform?: string | null,
        profileCompleteDismissed?: boolean | null,
        profileShareCodeRequested?: boolean | null,
        roles?: Array< string > | null,
        seizureAware?: number | null,
        seizureConvulsive?: number | null,
        seizureFrequency?: string | null,
        synchronizedAt?: string | null,
        tasksAddMeds?: string | null,
        tasksContactCaseManager?: string | null,
        tasksHealthData?: string | null,
        tasksLogFirstEvent?: string | null,
        tasksLogFirstMed?: string | null,
        tasksMedsTried?: string | null,
        tasksReadDreArticle?: string | null,
        tasksVisitVnsWebsite?: string | null,
        updatedAt?: string | null,
        watchRecordingEnabled?: boolean | null,
        yearsSinceDiagnosis?: string | null,
        zoneinfo?: string | null,
      } | null,
      userId?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListPatientProfileByOrganizationIdQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  organizationId: string,
};

export type ListPatientProfileByOrganizationIdQuery = {
  listPatientProfileByOrganizationId?:  {
    __typename: "PatientProfileList",
    items?:  Array< {
      __typename: "PatientProfile",
      IPGs?:  Array< {
        __typename: "IPG",
        implantingDate?: string | null,
        implantingHospitalSortName?: string | null,
        model?: string | null,
        replacementReason?: string | null,
        returnDate?: string | null,
        serial?: string | null,
        softwareVersion?: string | null,
      } | null > | null,
      assignedHcps:  Array< {
        __typename: "Hcp",
        invitation?:  {
          __typename: "Invitation",
          conversions?: Array< string | null > | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          invitationId: string,
          organizationId: string,
          recipientEmail?: string | null,
          recipientName?: string | null,
          recipientPhoneCountryCode?: string | null,
          recipientPhoneNumber?: string | null,
          redemptionLimit?: number | null,
          role?: HcpRole | null,
          senderUserId?: string | null,
          sentAt?: Array< string | null > | null,
          token?: string | null,
          type?: InvitationType | null,
          updatedAt?: string | null,
        } | null,
        invitationId?: string | null,
        memberId: string,
        organizationId: string,
        user?:  {
          __typename: "HcpUser",
          email: string,
          id: string,
          invitationStatus?: InvitationStatus | null,
          name?: string | null,
          npiNumber?: string | null,
          phoneNumber?: string | null,
          role?: HcpRole | null,
        } | null,
        userId?: string | null,
      } >,
      birthDate?: string | null,
      currentIPG?:  {
        __typename: "IPG",
        implantingDate?: string | null,
        implantingHospitalSortName?: string | null,
        model?: string | null,
        replacementReason?: string | null,
        returnDate?: string | null,
        serial?: string | null,
        softwareVersion?: string | null,
      } | null,
      deathDate?: string | null,
      disDate?: string | null,
      eosCalculation?:  {
        __typename: "EOSCalculation",
        calculationDate?: string | null,
        expectedLifeRemaining?: number | null,
        maximumLifeRemaining?: number | null,
        minimalLifeRemaining?: number | null,
      } | null,
      expDate?: string | null,
      gender?: string | null,
      id: string,
      indication?: string | null,
      lastReading?:  {
        __typename: "VNSReading",
        autoStimulationBlackoutTime?: string | null,
        autoStimulationOn?: boolean | null,
        autoStimulationThreshold?: string | null,
        autoStimulationTimePercent?: string | null,
        beatsPerMin?: string | null,
        chargePerDay?: number | null,
        dutyCycle?: string | null,
        fetDate?: string | null,
        heartbeatDetection?: number | null,
        id: string,
        importDate?: string | null,
        interrogationDate?: string | null,
        ipg?:  {
          __typename: "IPG",
          implantingDate?: string | null,
          implantingHospitalSortName?: string | null,
          model?: string | null,
          replacementReason?: string | null,
          returnDate?: string | null,
          serial?: string | null,
          softwareVersion?: string | null,
        } | null,
        isEOS?: boolean | null,
        isNEOS?: boolean | null,
        leadImpedanceStatus?: string | null,
        leadImpedanceValue?: string | null,
        magnetStimulationTime?: string | null,
        msid?: string | null,
        nightMode?: Flag | null,
        nightSettings?:  {
          __typename: "Settings",
          autoStimulationOnTime?: string | null,
          autoStimulationOutputCurrent?: string | null,
          autoStimulationPulseWidth?: string | null,
          endTime?: string | null,
          magnetOutputCurrent?: string | null,
          magnetPulseWidth?: string | null,
          magnetSignalOnTime?: string | null,
          normalFrequency?: string | null,
          normalOutputCurrent?: string | null,
          normalPulseWidth?: string | null,
          normalSignalOffTime?: string | null,
          normalSignalOnTime?: string | null,
          startTime?: string | null,
        } | null,
        normalSettings?:  {
          __typename: "Settings",
          autoStimulationOnTime?: string | null,
          autoStimulationOutputCurrent?: string | null,
          autoStimulationPulseWidth?: string | null,
          endTime?: string | null,
          magnetOutputCurrent?: string | null,
          magnetPulseWidth?: string | null,
          magnetSignalOnTime?: string | null,
          normalFrequency?: string | null,
          normalOutputCurrent?: string | null,
          normalPulseWidth?: string | null,
          normalSignalOffTime?: string | null,
          normalSignalOnTime?: string | null,
          startTime?: string | null,
        } | null,
        normalStimulationTime?: string | null,
        offTime?: string | null,
        onTime?: string | null,
        organizationId?: string | null,
        programmingMode?: string | null,
        readingMode?: VNSMode | null,
        scheduledPrograming?: Flag | null,
        seizureDetection?: Flag | null,
        sessionId?: number | null,
        tablet?:  {
          __typename: "VNSReadingTablet",
          serial?: string | null,
          softwareVersion?: string | null,
        } | null,
        therapyProtocolType?: string | null,
        time?: number | null,
        totalOnTimeHours?: string | null,
        totalOnTimeMinutes?: string | null,
        uniqueOfficeVisit?: string | null,
        wand?:  {
          __typename: "Wand",
          serial?: string | null,
          softwareVersion?: string | null,
        } | null,
      } | null,
      managingPhysician?:  {
        __typename: "Physician",
        NPI?: string | null,
        name?: string | null,
      } | null,
      msid?: string | null,
      name?: string | null,
      part?: string | null,
      patientId?: string | null,
      referringPhysician?:  {
        __typename: "Physician",
        NPI?: string | null,
        name?: string | null,
      } | null,
      surgeon?:  {
        __typename: "Physician",
        NPI?: string | null,
        name?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListTabletByOrganizationIdQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  organizationId: string,
};

export type ListTabletByOrganizationIdQuery = {
  listTabletByOrganizationId?:  {
    __typename: "TabletList",
    items?:  Array< {
      __typename: "Tablet",
      createdAt?: string | null,
      id: string,
      lastInterrogationDate?: string | null,
      nickname?: string | null,
      organizationId: string,
      serialNumber: string,
      totalPatients?: number | null,
      totalReadings?: number | null,
      updatedAt?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: UserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "UserList",
    items:  Array< {
      __typename: "User",
      acceptedAt?: string | null,
      analyticsId?: string | null,
      appsflyerId?: string | null,
      birthdate?: string | null,
      createdAt?: string | null,
      dob?: string | null,
      email: string,
      emailVerified?: boolean | null,
      gender?: string | null,
      id: string,
      includeInAnalytics?: boolean | null,
      isMinor?: boolean | null,
      isOnboard?: boolean | null,
      lastSentAt?: string | null,
      legacyId?: number | null,
      locale?: string | null,
      marketingOptIn?: boolean | null,
      name?: string | null,
      notificationsSound?: boolean | null,
      overlayIsFirstEventOverlayViewed?: boolean | null,
      overlayIsFirstMedOverlayViewed?: boolean | null,
      pastMedicationsCount?: string | null,
      phoneNumber?: string | null,
      platform?: string | null,
      profileCompleteDismissed?: boolean | null,
      profileShareCodeRequested?: boolean | null,
      roles?: Array< string > | null,
      seizureAware?: number | null,
      seizureConvulsive?: number | null,
      seizureFrequency?: string | null,
      synchronizedAt?: string | null,
      tasksAddMeds?: string | null,
      tasksContactCaseManager?: string | null,
      tasksHealthData?: string | null,
      tasksLogFirstEvent?: string | null,
      tasksLogFirstMed?: string | null,
      tasksMedsTried?: string | null,
      tasksReadDreArticle?: string | null,
      tasksVisitVnsWebsite?: string | null,
      updatedAt?: string | null,
      watchRecordingEnabled?: boolean | null,
      yearsSinceDiagnosis?: string | null,
      zoneinfo?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListVnsDeviceQueryVariables = {
  organizationId: string,
};

export type ListVnsDeviceQuery = {
  listVnsDevice:  {
    __typename: "VnsDeviceSummaryList",
    items:  Array< {
      __typename: "VnsDeviceSummary",
      blc?: string | null,
      generatorModel: string,
      generatorSerialNumber: number,
      implantedDate?: string | null,
      lastKnownReading?: string | null,
      normalOutputCurrent?: string | null,
      normalPulseWidth?: number | null,
      patientDob?: string | null,
      patientName?: string | null,
      patientProfileId: string,
    } >,
  },
};
