import { Grid } from "@mui/material"
import { Create, CreateProps, SaveButton, SimpleForm, TextInput, Toolbar, useNotify, useRedirect } from "react-admin"
import { useSearchParams } from "react-router-dom"

export const TabletCreate = (props: CreateProps) => {
  const [searchParams] = useSearchParams()
  const organizationId = searchParams.get("organizationId") || undefined
  const serialNumber = searchParams.get("serialNumber") || undefined
  const nickname = searchParams.get("nickname") || undefined

  const rediect = useRedirect()
  const notify = useNotify()

  if (!organizationId) {
    rediect("/organization")
    return null
  }

  const onError = (error: any) => {
    const message = error.errors[0].message || "ra.notification.http_error"
    notify(message, { type: "error" })
  }

  return (
    <Create
      title="Add Tablet"
      {...props}
      redirect={() => `organization/${organizationId}/show/tablets`}
      mutationOptions={{ onError }}
    >
      <SimpleForm
        reValidateMode="onSubmit"
        toolbar={
          <Toolbar>
            <SaveButton alwaysEnable={!!serialNumber} />
          </Toolbar>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextInput
              sx={{ display: "none" }}
              source="organizationId"
              defaultValue={organizationId}
              required
              disabled
            />
            <TextInput source="nickname" label="Nickname" defaultValue={nickname} fullWidth autoFocus />
            <TextInput
              source="serialNumber"
              label="Tablet Serial Number"
              defaultValue={serialNumber}
              fullWidth
              autoFocus
              required
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  )
}
